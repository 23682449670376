
import { ref } from "vue";
import { createNewToken } from "@/solana/token";
import { chosenCluster } from "@/solana/connection";
import * as SolanaErrorHandler from "@/solana/SolanaErrorHandler";
import SecretFormField from "@/components/util/SecretFormField.vue";
import CopyIcon from "@/components/util/CopyIcon.vue";
import PublicKeyFormField from "@/components/util/PublicKeyFormField.vue";
import Heading from "@/components/util/Heading.vue";

export default {
  components: {
    SecretFormField,
    CopyIcon,
    PublicKeyFormField,
    Heading
  },
  setup() {
    const payerSecret = ref("");
    const mintAuthorityAddress = ref("");
    const freezeAuthorityAddress = ref("");
    const tokenDecimals = ref(0);
    const createdTokenAddress = ref("");
    const creatingToken = ref(false);
    const tokenLink = ref("");
    const errorMessage = ref("");
    const signExternally = ref(true);

    const createToken = async () => {
      tokenLink.value = "";
      createdTokenAddress.value = "";
      creatingToken.value = true;
      errorMessage.value = "";
      try {
        createdTokenAddress.value = await createNewToken(
          payerSecret.value,
          mintAuthorityAddress.value,
          freezeAuthorityAddress.value,
          tokenDecimals.value,
          signExternally.value
        );
        tokenLink.value = `https://explorer.solana.com/address/${createdTokenAddress.value}?cluster=${chosenCluster.value}`;
      } catch (err) {
        errorMessage.value = SolanaErrorHandler.getErrorMessage(err);
      }

      creatingToken.value = false;
    };

    return {
      payerSecret,
      mintAuthorityAddress,
      freezeAuthorityAddress,
      tokenDecimals,
      createdTokenAddress,
      createToken,
      creatingToken,
      tokenLink,
      errorMessage,
      signExternally
    };
  }
};
